(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BgSrcSet = function () {
  function BgSrcSet() {
    _classCallCheck(this, BgSrcSet);

    this.called = false;
    this.callonce = true;
  }

  _createClass(BgSrcSet, [{
    key: 'init',
    value: function init(target, callback) {
      //retina bool
      this.retina = window.devicePixelRatio > 1;

      //storage for our elements
      this.elements = [];

      //global onload callback for imagery
      this.callback = typeof callback === 'function' ? callback : function () {};

      //window width, for responsive handling
      this.curwidth = this.getWidth();

      //get our input and turn it into an element list of some sort
      var elems = this.gather(target);

      //parse the element input
      for (var i = 0, l = elems.length; i < l; i++) {
        this.parse(elems[i]);
      }

      this.set();
      this.resize();
    }

    /* -----------* /
       Gather elements
    / *----------- */

  }, {
    key: 'gather',
    value: function gather(target) {
      var autotypes = ['HTMLCollection', 'NodeList'];
      var e = target;
      var type = e.nodeType ? 'Object' : Object.prototype.toString.call(e).replace(/^\[object |\]$/g, '');

      var func = 'parse' + type;

      if (autotypes.indexOf(type) > -1) {
        return e;
      }

      if (this[func]) {
        return this[func](e);
      }

      return [];
    }
  }, {
    key: 'parseObject',
    value: function parseObject(target) {
      return target.nodeType ? [target] : [];
    }
  }, {
    key: 'parseArray',
    value: function parseArray(target) {
      return target;
    }
  }, {
    key: 'parseString',
    value: function parseString(target) {
      var d = document;
      var e = target.trim();
      var sel = e[0];
      var elems = [];

      switch (true) {
        /* class */
        case sel === '.':
          elems = d.getElementsByClassName(e.substring(1));
          break;
        /* id */
        case sel === '#':
          elems.push(d.getElementById(e.substring(1)));
          break;
        /* tag */
        case /^[a-zA-Z]+$/.test(e):
          elems = d.getElementsByTagName(e);
          break;
        /* unknown */
        default:
          elems = [];
      }

      return elems;
    }

    /* -----------* /
       Parse datasrc
    / *----------- */

  }, {
    key: 'parse',
    value: function parse(obj) {
      //our data to parase
      var bgss = obj.getAttribute('bg-srcset');
      /* exit if no attribute */
      if (attr === null) {
        return false;
      }

      /* create new element object */
      this.elements.push({});

      /* split up sets */
      var set = bgss.split(',');
      var attr = '';
      var curelem = this.elements[this.elements.length - 1];

      curelem.node = obj;
      curelem.srcset = [];

      /* loop through sets to define breakpoints */
      for (var i = 0, l = set.length; i < l; i++) {
        curelem.srcset.push({});
        attr = set[i].trim();
        var attrs = attr.split(' ');
        var a;
        var e;
        var t;
        /* since we can't know the order of the values, starting another loop */
        for (var attrc = 0, attrl = attrs.length; attrc < attrl; attrc++) {
          a = attrs[attrc];
          e = curelem.srcset[i]; //current attribute
          t = a.length - 1;
          switch (true) {
            case a.trim() === "":
              //in case of extra white spaces
              continue;
            case a[t] !== 'w' && a[a.length - 1] !== 'x':
              e.src = a;
              break;
            case a[t] === 'w':
              e.width = parseInt(a.slice(0, -1));
              break;
            case a[t] === 'x':
              e.retina = parseInt(a.slice(0, -1)) > 1;
              break;
          }
          if (!e.width) {
            e.width = Number.POSITIVE_INFINITY;
          } //set to the top
          if (!e.retina) {
            e.retina = false;
          }
        }
      }
    }

    /* -----------* /
       Set image
    / *----------- */

  }, {
    key: 'set',
    value: function set() {
      for (var i = 0, l = this.elements.length; i < l; i++) {
        this.setSingle(i);
      }
    }
  }, {
    key: 'setSingle',
    value: function setSingle(id) {
      var width = 0,
          elem = this.elements[id],
          comparray = [],
          best = 0,
          _this = this;

      width = this.getWidth(); //elem.node.offsetWidth;

      elem.srcset = elem.srcset.sort(this.dynamicSort("width"));

      for (var i = 0, l = elem.srcset.length; i < l; i++) {
        if (elem.srcset[i].width < width) {
          continue;
        }
        comparray.push(elem.srcset[i]);
      }
      if (comparray.length === 0) {
        comparray.push(elem.srcset[elem.srcset.length - 1]);
      }

      best = comparray[0];

      if (comparray.length > 1 && comparray[0].width === comparray[1].width) {
        best = comparray[0].retina !== this.retina ? comparray[1] : comparray[0];
      }

      if (best.src !== undefined && best.src !== 'null') {
        var img = new Image();
        var done = false;

        img.onload = img.onreadystatechange = function () {
          if (!done && (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")) {
            done = true;

            elem.node.style.backgroundImage = "url('" + best.src + "')";

            /* only fire the callback on initial load, not resize events */
            if (!_this.called) {

              _this.callback(elem);
              _this.called = _this.callonce;
            }
          }
        };

        img.src = best.src;
      } else {
        elem.node.style.backgroundImage = "";
      }
    }

    /* -----------* /
       Handle Resize
    / *----------- */

  }, {
    key: 'resize',
    value: function resize() {
      var _this = this,
          resizeTimer = setTimeout(function () {}, 0);

      this.addEvent(window, 'resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          var w = _this.getWidth();
          if (w !== _this.curwidth) {
            _this.curwidth = w;
            _this.set();
          }
        }, 250);
      });
    }
  }, {
    key: 'addEvent',
    value: function addEvent(elem, evName, fn) {
      elem.addEventListener(evName, fn, false);
    }
  }, {
    key: 'getWidth',
    value: function getWidth() {
      var w, d, e, g;
      w = window;
      d = document;
      e = d.documentElement;
      g = d.getElementsByTagName('body')[0];

      return w.innerWidth || e.clientWidth || g.clientWidth;
    }
  }, {
    key: 'dynamicSort',
    value: function dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
  }]);

  return BgSrcSet;
}();

exports.default = BgSrcSet;

},{}],2:[function(require,module,exports){
'use strict';

var _BgSrcSet = require('./BgSrcSet');

var _BgSrcSet2 = _interopRequireDefault(_BgSrcSet);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var bgss = new _BgSrcSet2.default();

bgss.callonce = false;

bgss.init('.BgSrc', function (a) {
  a.node.classList.add('BgSrc__loaded');
});

var hamburger = document.querySelector('.hamburger'),
    menu = document.querySelector('.Header__menu');

hamburger.addEventListener('click', function () {
  var isOpen = hamburger.classList.contains('is-open');

  if (isOpen) {
    hamburger.classList.remove('is-open');
    hamburger.classList.add('is-closed');
    menu.classList.remove('is-open');
  } else {
    hamburger.classList.remove('is-closed');
    hamburger.classList.add('is-open');
    menu.classList.add('is-open');
  }
}, false);

},{"./BgSrcSet":1}]},{},[2]);
